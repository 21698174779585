/**
 * This is a script that consumers may insert into their website in order to embed their Lead Forms.
 * This script defines and registers a cpro-form Custom Web Component (CWC). It is up to consumer to then position
 * the defined <cpro-form> component inside their website where it will automatically insert an iframe that loads the
 * Lead Form.
 * This CWB will auto-resize the iframe based on the height of the iframe content.
 */
type Payload = {
    leadForm: {
        redirectUri: string
    },
    source: 'LEAD-FORM'
    error: string
}

((d: Document, w: Window) => {
    const tag = 'cpro-form';
    const formId = 'form-id';
    const isPreview = 'is-preview';

    if (typeof import.meta.env.REACT_APP_HOSTNAME !== 'string') {
        throw new Error('REACT_APP_HOSTNAME is not defined');
    }
    const hostname: string = import.meta.env.REACT_APP_HOSTNAME;

    const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value);

    class CproForm extends HTMLElement {

        shadow: ShadowRoot;
        iframe?: HTMLIFrameElement | null = null;

        static observedAttributes = [formId, isPreview];

        constructor() {
            super();
            this.shadow = this.attachShadow({ mode: 'closed' });
        }

        attributeChangedCallback(): void {
            if (!this.iframe) return this.createIframe();
            this.iframe.setAttribute('src', this.getIframeSource());
        }

        listener(e: MessageEvent<Payload>): void {
            if (!this.iframe) return;
            if (!e.data) return;
            const data: Payload = e.data;
            if (isNumber(data)) {
                this.iframe.height = `${data}px`;
                return;
            }

            try {
                if (data?.source === 'LEAD-FORM' && data.leadForm?.redirectUri) {
                    window.location.href = data.leadForm.redirectUri;
                }
            } catch (error) {
                console.error(error);
            }
        }

        getIframeSource(): string {
            const formIdVal = this.getAttribute(formId);
            const isPreviewVal = this.getAttribute(isPreview) === 'true' ? 1 : 0;
            return `${hostname}/${formIdVal}?isPreview=${isPreviewVal}`;
        }

        createIframe() {
            if (this.iframe) return;
            const iframe: HTMLIFrameElement = this.iframe = d.createElement('iframe');

            iframe.setAttribute('sandbox', 'allow-forms allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin');
            iframe.src = this.getIframeSource();
            iframe.className = `${tag}-iframe`;
            iframe.loading = 'auto';
            iframe.width = '100%';
            iframe.height = '500px';
            iframe.style.border = '0';
            iframe.style.width = '100%';
            this.shadow.appendChild(iframe);

            w.addEventListener('message', this.listener.bind(this));
        }

        disconnectedCallback() {
            w.removeEventListener('message', this.listener.bind(this));
        }

        connectedCallback() {
            if (!this.getAttribute(formId)) {
                console.error(`"${formId}" attribute is required on the <${tag}> element`);
                return;
            }

            this.createIframe();

        }

    }

    const ctor = w.customElements.get(tag);
    if (ctor) {
        console.error(`"${tag}" component is already defined`);
        return;
    }

    w.customElements.define(tag, CproForm);

})(document, window);
